import React, { useState } from 'react';
import { Card, Accordion, ProgressBar, Row, Col, Button, Dropdown } from 'react-bootstrap';
import './ClassReport.css';
import scales from '../../ACES_Assessment/scales.js';
import RosterAccordionScale from '../AccordionScale/RosterAccordionScale';

function CourseReport(props) {
  console.log(props)

  const styles = {
    scaleTitle: {
      textAlign: 'right'
    },
    scaleTitleMobile: {
      textAlign: 'left'
    },
    dropdownButton: {
      boxShadow: 'none',
      outline: 'none'
    }
  }

  const [scaleShowName, setScaleShowName] = useState(scales[0].name)

  const handleSelect = (event) => {
    setScaleShowName(event.target.name)
    console.log(scaleShowName);
  }

  // sort function that takes an array and will return new array with items in order from largest to smallest
  const sortValuesDescending = (arr, index, totalArr) => {
    // console.log(arr);
    arr[props.scoreType][props.scoreCohort].sort(function (a, b) {
      return b.highScoreCountPercent - a.highScoreCountPercent
    })

    let newArr = []
    for (let i = 0; i < arr[props.scoreType][props.scoreCohort].length; i++) {
      console.log(totalArr[index].progressScores[props.scoreCohort][i])
      newArr.push({
        'progress': totalArr[index].progressScores[props.scoreCohort][i],
        'initial': totalArr[index].initialScores[props.scoreCohort][i]
      })
    }
    console.log(newArr)
    return newArr
  }

  const sortStrengths = (arr, scaleIndex) => {

    const classStrengthsArr = []
    // sort the percentile array into descending order so that top strengths will appear first
    console.log(arr);
    const descendingArr = []
    arr.forEach((scale, scaleIndex) => {
      descendingArr.push(sortValuesDescending(scale, scaleIndex, arr))
    })
    console.log(descendingArr);

    // map descending array, sorting out into 3 tiers and pushing into appropriate object in strengthsArr
    descendingArr.forEach((scale, index) => {
      // instantiate an array to hold the 3 tiers of skill
      const strengthsArr = [{ "Strengths": [] }, { "Developing_Strengths": [] }, { "Growth_Areas": [] }]
      scale.forEach(classScoreObj => {
        if (classScoreObj.highScoreCountPercent > 30) {
          const arrCopy1 = strengthsArr[0].Strengths
          let arr1 = []
          arrCopy1 === undefined ? arr1 = [] : arr1 = [...arrCopy1]
          arr1.push(classScoreObj)
          strengthsArr[0].Strengths = arr1
        } else if (classScoreObj.highScoreCountPercent <= 30 && classScoreObj.highScoreCountPercent > 20) {
          const arrCopy2 = strengthsArr[1].Developing_Strengths
          let arr2 = []
          arrCopy2 === undefined ? arr2 = [] : arr2 = [...arrCopy2]
          arr2.push(classScoreObj)
          strengthsArr[1].Developing_Strengths = arr2
        } else {
          const arrCopy3 = strengthsArr[2].Growth_Areas
          let arr3 = []
          arrCopy3 === undefined ? arr3 = [] : arr3 = [...arrCopy3]
          arr3.push(classScoreObj)
          strengthsArr[2].Growth_Areas = arr3
        }
      })
      classStrengthsArr.push(strengthsArr)
    })
    return classStrengthsArr
  }

  const strengthsArr = sortStrengths([...props.reportsDataArr])
  console.log(strengthsArr)

  return (
    <div>
      <div className='report-instructions'>
        {props.ReportDescription}
      </div>
      <div className={'roster-dropdown'}>
        <Dropdown>
          <Dropdown.Toggle variant="outline-dark" id="dropdown-basic" style={styles.dropdownButton} >
            {scaleShowName}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {scales.map(scale => (
              <Dropdown.Item as="button" name={scale.name} onClick={handleSelect}>{scale.name}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {props.strengthsViewOn ?
        strengthsArr.map((scale, scaleIndex) => (
          <Accordion style={scales[scaleIndex].name === scaleShowName ? { display: 'inherit' } : { display: 'none' }}>
            <Card style={styles.strengthsCard}>
              <Card.Body style={styles.strengthsCardBody} >
                <h5 className='strengths-header'>{props.cohortName} Strengths</h5>
                <div style={{ margin: '20px 0px' }}>
                  {scale[0].Strengths.length === 0 ? <li>[empty]</li> : (
                    scale[0].Strengths.map((classScore, classIndex) => (
                      <Accordion.Toggle as={Button} variant="link" eventKey={classIndex} style={{ width: '100%', boxShadow: 'none', padding: '0' }}>
                        <Row className={props.scoreTypeAlt ? 'comparison-progress-row' : null}>
                          <Col xs={12} md={4} style={window.innerWidth <= 740 ? styles.scaleTitleMobile : styles.scaleTitle}>
                            <span style={{ fontWeight: "400" }}>{`Section-${classScore.classID}`}</span>
                          </Col>
                          <Col xs={12} md={8} style={{ margin: 'auto' }}>
                            <ProgressBar style={{ position: 'relative' }}>
                              <ProgressBar now={classScore.lowScoreCountPercent} label={`${classScore.lowScoreCountPercent}%`} style={{ backgroundColor: '#4da3ff' }} />
                              <ProgressBar now={classScore.moderateScoreCountPercent} label={`${classScore.moderateScoreCountPercent}%`} />
                              <ProgressBar now={classScore.highScoreCountPercent} label={`${classScore.highScoreCountPercent}%`} style={{ backgroundColor: '#0056b3' }} />
                            </ProgressBar>
                          </Col>
                          {props.scoreTypeAlt ?
                            <>
                              <Col xs={12} md={4} style={window.innerWidth <= 740 ? styles.scaleTitleMobile : styles.scaleTitle}>
                              </Col>
                              <Col xs={12} md={8} style={{ margin: 'auto' }}>
                                <ProgressBar style={{ position: 'relative' }}>
                                  <ProgressBar animated now={scale[props.scoreTypeAlt][props.scoreCohort][classIndex].lowScoreCountPercent} label={`${scale[props.scoreTypeAlt][props.scoreCohort][classIndex].lowScoreCountPercent}%`} style={{ backgroundColor: '#4da3ff' }} />
                                  <ProgressBar animated now={scale[props.scoreTypeAlt][props.scoreCohort][classIndex].moderateScoreCountPercent} label={`${scale[props.scoreTypeAlt][props.scoreCohort][classIndex].moderateScoreCountPercent}%`} />
                                  <ProgressBar animated now={scale[props.scoreTypeAlt][props.scoreCohort][classIndex].highScoreCountPercent} label={`${scale[props.scoreTypeAlt][props.scoreCohort][classIndex].highScoreCountPercent}%`} style={{ backgroundColor: '#0056b3' }} />
                                </ProgressBar>
                              </Col>
                            </>
                            :
                            null}
                        </Row>
                      </Accordion.Toggle>
                    ))
                  )
                  }
                </div>
                <h5 className='strengths-header'>{props.cohortName} Developing Strengths</h5>
                <div style={{ margin: '20px 0px' }}>
                  {scale[1].Developing_Strengths.length === 0 ? <li>[empty]</li> : (
                    scale[1].Developing_Strengths.map((classScore, index) => (
                      <Accordion.Toggle as={Button} variant="link" eventKey={index} style={{ width: '100%', boxShadow: 'none', padding: '0' }}>
                        <Row className={props.scoreTypeAlt ? 'comparison-progress-row' : null}>
                          <Col xs={12} md={4} style={window.innerWidth <= 740 ? styles.scaleTitleMobile : styles.scaleTitle}>
                            <span style={{ fontWeight: "400" }}>{`Section-${classScore.classID}`}</span>
                          </Col>
                          <Col xs={12} md={8} style={{ margin: 'auto' }}>
                            <ProgressBar style={{ position: 'relative' }}>
                              <ProgressBar now={classScore.lowScoreCountPercent} label={`${classScore.lowScoreCountPercent}%`} style={{ backgroundColor: '#4da3ff' }} />
                              <ProgressBar now={classScore.moderateScoreCountPercent} label={`${classScore.moderateScoreCountPercent}%`} />
                              <ProgressBar now={classScore.highScoreCountPercent} label={`${classScore.highScoreCountPercent}%`} style={{ backgroundColor: '#0056b3' }} />
                            </ProgressBar>
                          </Col>
                        </Row>
                      </Accordion.Toggle>
                    ))
                  )}
                </div>
                <h5 className='strengths-header'>{props.cohortName} Growth Areas</h5>
                <div style={{ margin: '20px 0px' }}>
                  {scale[2].Growth_Areas.length === 0 ? <li>[empty]</li> : (
                    scale[2].Growth_Areas.map((classScore, index) => (
                      <Accordion.Toggle as={Button} variant="link" eventKey={index} style={{ width: '100%', boxShadow: 'none', padding: '0' }}>
                        <Row className={props.scoreTypeAlt ? 'comparison-progress-row' : null}>
                          <Col xs={12} md={4} style={window.innerWidth <= 740 ? styles.scaleTitleMobile : styles.scaleTitle}>
                            <span style={{ fontWeight: "400" }}>{`Section-${classScore.classID}`}</span>
                          </Col>
                          <Col xs={12} md={8} style={{ margin: 'auto' }}>
                            <ProgressBar style={{ position: 'relative' }}>
                              <ProgressBar now={classScore.lowScoreCountPercent} label={`${classScore.lowScoreCountPercent}%`} style={{ backgroundColor: '#4da3ff' }} />
                              <ProgressBar now={classScore.moderateScoreCountPercent} label={`${classScore.moderateScoreCountPercent}%`} />
                              <ProgressBar now={classScore.highScoreCountPercent} label={`${classScore.highScoreCountPercent}%`} style={{ backgroundColor: '#0056b3' }} />
                            </ProgressBar>
                          </Col>
                        </Row>
                      </Accordion.Toggle>
                    ))
                  )}
                </div>
              </Card.Body>
            </Card>
          </Accordion>
        ))
        :
        props.reportsDataArr.map((scale, index) => (
          <Accordion style={scale.scaleName === scaleShowName ? { display: 'inherit' } : { display: 'none' }}>
            {scale[props.scoreType][props.scoreCohort].map((classScore, classIndex) => (
              <Card style={{ border: 'none' }}>
                <Accordion.Toggle as={Button} variant="link" eventKey={index} style={{ width: '100%', boxShadow: 'none', padding: '0' }}>
                  <Row className={props.scoreTypeAlt ? 'comparison-progress-row' : null}>
                    <Col xs={12} md={4} style={window.innerWidth <= 740 ? styles.scaleTitleMobile : styles.scaleTitle}>
                      <span style={{ fontWeight: "400" }}>{`Section-${classScore.classID}`}</span>
                    </Col>
                    <Col xs={12} md={8} style={{ margin: 'auto' }}>
                      {props.scoreType === 'changeScores' ?
                        <ProgressBar style={{ position: 'relative' }}>
                          <ProgressBar now={classScore.rawScoreAvgChangePercent} label={`${classScore.rawScoreAvgChangePercent}%`} style={classScore.rawScoreAvgChangePercent < 0 ? { backgroundColor: 'red' } : {}} />
                        </ProgressBar>
                        :
                        <ProgressBar style={{ position: 'relative' }}>
                          <ProgressBar now={classScore.lowScoreCountPercent} label={`${classScore.lowScoreCountPercent}%`} style={{ backgroundColor: '#4da3ff' }} />
                          <ProgressBar now={classScore.moderateScoreCountPercent} label={`${classScore.moderateScoreCountPercent}%`} />
                          <ProgressBar now={classScore.highScoreCountPercent} label={`${classScore.highScoreCountPercent}%`} style={{ backgroundColor: '#0056b3' }} />
                        </ProgressBar>
                      }

                    </Col>
                    {props.scoreTypeAlt ?
                      <>
                        <Col xs={12} md={4} style={window.innerWidth <= 740 ? styles.scaleTitleMobile : styles.scaleTitle}>
                        </Col>
                        <Col xs={12} md={8} style={{ margin: 'auto' }}>
                          <ProgressBar style={{ position: 'relative' }}>
                            <ProgressBar animated now={scale[props.scoreTypeAlt][props.scoreCohort][classIndex].lowScoreCountPercent} label={`${scale[props.scoreTypeAlt][props.scoreCohort][classIndex].lowScoreCountPercent}%`} style={{ backgroundColor: '#4da3ff' }} />
                            <ProgressBar animated now={scale[props.scoreTypeAlt][props.scoreCohort][classIndex].moderateScoreCountPercent} label={`${scale[props.scoreTypeAlt][props.scoreCohort][classIndex].moderateScoreCountPercent}%`} />
                            <ProgressBar animated now={scale[props.scoreTypeAlt][props.scoreCohort][classIndex].highScoreCountPercent} label={`${scale[props.scoreTypeAlt][props.scoreCohort][classIndex].highScoreCountPercent}%`} style={{ backgroundColor: '#0056b3' }} />
                          </ProgressBar>
                        </Col>
                      </>
                      :
                      null}
                  </Row>
                </Accordion.Toggle>
              </Card>
            ))}
          </Accordion>
        ))
      }
    </div >
  )
}

export default CourseReport;