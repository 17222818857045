const normTable = [

  // These norm tables are not based on any real data--they are created from a simple linear formula to serve as a placeholder.

  // Scale 1: 6 items
  [{ "6": 1 }, { "7": 1 }, { "8": 1 }, { "9": 1 }, { "10": 1 }, { "11": 1 }, { "12": 1 }, { "13": 1 }, { "14": 1 }, { "15": 2 }, { "16": 5 }, { "17": 9 }, { "18": 16 }, { "19": 25 }, { "20": 37 }, { "21": 50 }, { "22": 63 }, { "23": 75 }, { "24": 84 }, { "25": 91 }, { "26": 95 }, { "27": 98 }, { "28": 99 }, { "29": 99 }, { "30": 99 }, { "31": 99 }, { "32": 99 }, { "33": 99 }, { "34": 99 }, { "35": 99 }, { "36": 99 }],

  // Scale2: 8 items
  [{ "8": 1 }, { "9": 1 }, { "10": 1 }, { "11": 1 }, { "12": 1 }, { "13": 2 }, { "14": 2 }, { "15": 3 }, { "16": 4 }, { "17": 6 }, { "18": 8 }, { "19": 10 }, { "20": 13 }, { "21": 16 }, { "22": 20 }, { "23": 24 }, { "24": 28 }, { "25": 33 }, { "26": 39 }, { "27": 44 }, { "28": 50 }, { "29": 56 }, { "30": 61 }, { "31": 67 }, { "32": 72 }, { "33": 76 }, { "34": 80 }, { "35": 84 }, { "36": 87 }, { "37": 90 }, { "38": 92 }, { "39": 94 }, { "40": 96 }, { "41": 97 }, { "42": 98 }, { "43": 98 }, { "44": 99 }, { "45": 99 }, { "46": 99 }, { "47": 99 }, { "48": 99 }],


  // Scale 3: 9 items
  [{ "9": 1 }, { "10": 1 }, { "11": 1 }, { "12": 1 }, { "13": 2 }, { "14": 2 }, { "15": 3 }, { "16": 4 }, { "17": 5 }, { "18": 6 }, { "19": 7 }, { "20": 9 }, { "21": 11 }, { "22": 13 }, { "23": 16 }, { "24": 19 }, { "25": 22 }, { "26": 25 }, { "27": 29 }, { "28": 33 }, { "29": 37 }, { "30": 41 }, { "31": 46 }, { "32": 50 }, { "33": 54 }, { "34": 59 }, { "35": 63 }, { "36": 67 }, { "37": 71 }, { "38": 75 }, { "39": 78 }, { "40": 81 }, { "41": 84 }, { "42": 87 }, { "43": 89 }, { "44": 91 }, { "45": 93 }, { "46": 94 }, { "47": 95 }, { "48": 96 }, { "49": 97 }, { "50": 98 }, { "51": 98 }, { "52": 99 }, { "53": 99 }, { "54": 99 }],

  // Scale 4: 7 items
  [{ "7": 1 }, { "8": 1 }, { "9": 1 }, { "10": 1 }, { "11": 1 }, { "12": 1 }, { "13": 1 }, { "14": 1 }, { "15": 1 }, { "16": 2 }, { "17": 4 }, { "18": 5 }, { "19": 8 }, { "20": 12 }, { "21": 16 }, { "22": 21 }, { "23": 27 }, { "24": 34 }, { "25": 42 }, { "26": 50 }, { "27": 58 }, { "28": 66 }, { "29": 73 }, { "30": 79 }, { "31": 84 }, { "32": 88 }, { "33": 92 }, { "34": 95 }, { "35": 96 }, { "36": 98 }, { "37": 99 }, { "38": 99 }, { "39": 99 }, { "40": 99 }, { "41": 99 }, { "42": 99 }],

  // Scale 5: 7 items
  [{ "7": 1 }, { "8": 1 }, { "9": 1 }, { "10": 1 }, { "11": 1 }, { "12": 1 }, { "13": 1 }, { "14": 1 }, { "15": 1 }, { "16": 2 }, { "17": 4 }, { "18": 5 }, { "19": 8 }, { "20": 12 }, { "21": 16 }, { "22": 21 }, { "23": 27 }, { "24": 34 }, { "25": 42 }, { "26": 50 }, { "27": 58 }, { "28": 66 }, { "29": 73 }, { "30": 79 }, { "31": 84 }, { "32": 88 }, { "33": 92 }, { "34": 95 }, { "35": 96 }, { "36": 98 }, { "37": 99 }, { "38": 99 }, { "39": 99 }, { "40": 99 }, { "41": 99 }, { "42": 99 }],

  // Scale 6: 7 items
  [{ "7": 1 }, { "8": 1 }, { "9": 1 }, { "10": 1 }, { "11": 1 }, { "12": 1 }, { "13": 1 }, { "14": 1 }, { "15": 1 }, { "16": 2 }, { "17": 4 }, { "18": 5 }, { "19": 8 }, { "20": 12 }, { "21": 16 }, { "22": 21 }, { "23": 27 }, { "24": 34 }, { "25": 42 }, { "26": 50 }, { "27": 58 }, { "28": 66 }, { "29": 73 }, { "30": 79 }, { "31": 84 }, { "32": 88 }, { "33": 92 }, { "34": 95 }, { "35": 96 }, { "36": 98 }, { "37": 99 }, { "38": 99 }, { "39": 99 }, { "40": 99 }, { "41": 99 }, { "42": 99 }],

  // Scale 7: 6 items
  [{ "6": 1 }, { "7": 1 }, { "8": 1 }, { "9": 1 }, { "10": 1 }, { "11": 1 }, { "12": 1 }, { "13": 1 }, { "14": 1 }, { "15": 2 }, { "16": 5 }, { "17": 9 }, { "18": 16 }, { "19": 25 }, { "20": 37 }, { "21": 50 }, { "22": 63 }, { "23": 75 }, { "24": 84 }, { "25": 91 }, { "26": 95 }, { "27": 98 }, { "28": 99 }, { "29": 99 }, { "30": 99 }, { "31": 99 }, { "32": 99 }, { "33": 99 }, { "34": 99 }, { "35": 99 }, { "36": 99 }],

  // Scale 8: 7 items
  [{ "7": 1 }, { "8": 1 }, { "9": 1 }, { "10": 1 }, { "11": 1 }, { "12": 1 }, { "13": 1 }, { "14": 1 }, { "15": 1 }, { "16": 2 }, { "17": 4 }, { "18": 5 }, { "19": 8 }, { "20": 12 }, { "21": 16 }, { "22": 21 }, { "23": 27 }, { "24": 34 }, { "25": 42 }, { "26": 50 }, { "27": 58 }, { "28": 66 }, { "29": 73 }, { "30": 79 }, { "31": 84 }, { "32": 88 }, { "33": 92 }, { "34": 95 }, { "35": 96 }, { "36": 98 }, { "37": 99 }, { "38": 99 }, { "39": 99 }, { "40": 99 }, { "41": 99 }, { "42": 99 }],

  // Scale 9: 7 items
  [{ "7": 1 }, { "8": 1 }, { "9": 1 }, { "10": 1 }, { "11": 1 }, { "12": 1 }, { "13": 1 }, { "14": 1 }, { "15": 1 }, { "16": 2 }, { "17": 4 }, { "18": 5 }, { "19": 8 }, { "20": 12 }, { "21": 16 }, { "22": 21 }, { "23": 27 }, { "24": 34 }, { "25": 42 }, { "26": 50 }, { "27": 58 }, { "28": 66 }, { "29": 73 }, { "30": 79 }, { "31": 84 }, { "32": 88 }, { "33": 92 }, { "34": 95 }, { "35": 96 }, { "36": 98 }, { "37": 99 }, { "38": 99 }, { "39": 99 }, { "40": 99 }, { "41": 99 }, { "42": 99 }],

  // Scale 10: 7 items
  [{ "7": 1 }, { "8": 1 }, { "9": 1 }, { "10": 1 }, { "11": 1 }, { "12": 1 }, { "13": 1 }, { "14": 1 }, { "15": 1 }, { "16": 2 }, { "17": 4 }, { "18": 5 }, { "19": 8 }, { "20": 12 }, { "21": 16 }, { "22": 21 }, { "23": 27 }, { "24": 34 }, { "25": 42 }, { "26": 50 }, { "27": 58 }, { "28": 66 }, { "29": 73 }, { "30": 79 }, { "31": 84 }, { "32": 88 }, { "33": 92 }, { "34": 95 }, { "35": 96 }, { "36": 98 }, { "37": 99 }, { "38": 99 }, { "39": 99 }, { "40": 99 }, { "41": 99 }, { "42": 99 }],

  // Scale 11: 7 items
  [{ "7": 1 }, { "8": 1 }, { "9": 1 }, { "10": 1 }, { "11": 1 }, { "12": 1 }, { "13": 1 }, { "14": 1 }, { "15": 1 }, { "16": 2 }, { "17": 4 }, { "18": 5 }, { "19": 8 }, { "20": 12 }, { "21": 16 }, { "22": 21 }, { "23": 27 }, { "24": 34 }, { "25": 42 }, { "26": 50 }, { "27": 58 }, { "28": 66 }, { "29": 73 }, { "30": 79 }, { "31": 84 }, { "32": 88 }, { "33": 92 }, { "34": 95 }, { "35": 96 }, { "36": 98 }, { "37": 99 }, { "38": 99 }, { "39": 99 }, { "40": 99 }, { "41": 99 }, { "42": 99 }],

  // Scale 12: 7 items
  [{ "7": 1 }, { "8": 1 }, { "9": 1 }, { "10": 1 }, { "11": 1 }, { "12": 1 }, { "13": 1 }, { "14": 1 }, { "15": 1 }, { "16": 2 }, { "17": 4 }, { "18": 5 }, { "19": 8 }, { "20": 12 }, { "21": 16 }, { "22": 21 }, { "23": 27 }, { "24": 34 }, { "25": 42 }, { "26": 50 }, { "27": 58 }, { "28": 66 }, { "29": 73 }, { "30": 79 }, { "31": 84 }, { "32": 88 }, { "33": 92 }, { "34": 95 }, { "35": 96 }, { "36": 98 }, { "37": 99 }, { "38": 99 }, { "39": 99 }, { "40": 99 }, { "41": 99 }, { "42": 99 }],

]

// export default normTable;
export default normTable;